import ErrorOverlay from 'components/Common/ErrorOverlay'
import { findObjectKind, isTrackSection } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { ShortMidiObject } from 'objects/types'
import { ObjectKind } from 'objects/types/const'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeedbackState } from 'reducers/feedback'
import { disableGeoEditor } from 'reducers/geoEditor'
import { DetailsPanelState, resetOpenMenus, updateItem } from 'reducers/panels/detailsPanel'
import { RootState } from 'Store'

import { MapState } from 'reducers/map'
import PanelNavigator from '../PanelNavigator'
import PanelTemplate from '../PanelTemplate/PanelTemplate'
import './DetailsPanel.scss'
import DetailsPanelButtons from './DetailsPanelButtons'
import DetailsPanelContent from './DetailsPanelContent'
import DetailsPanelHeader from './DetailsPanelHeader'
import { DETAILS_PANEL_TABS, shouldDisplayTab } from './utils'

const CRITICAL_ERROR_CODES = [403, 404, 409, 500]

export default function DetailPanel(): ReactElement | null {
  const dispatch = useDispatch()
  const {
    item, isLoading, shouldRefresh,
  } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const { feedback } = useSelector((state: RootState): FeedbackState => state.feedback)
  const { selectedSubnet } = useSelector((state: RootState): MapState => state.map)
  const initialKind = item ? findObjectKind(item) : ObjectKind.TrackSection
  const [kind, setKind] = useState<ObjectKind>(initialKind)

  const [value, setValue] = useState(DETAILS_PANEL_TABS[0].value)
  const [tabs, setTabs] = useState(DETAILS_PANEL_TABS)

  const getNewInfo = (newKind: ObjectKind, newItem: ShortMidiObject) => {
    dispatch(GetDetailsServiceOfKind[newKind](newItem.itemId || newItem.id))
    dispatch(GetGeometryServiceOfKind[newKind](newItem.itemId || newItem.id))
  }

  const updateInfo = () => {
    if (item !== undefined) {
      const newKind = findObjectKind(item)
      if (kind !== newKind || shouldRefresh) {
        const newTabs = DETAILS_PANEL_TABS.filter(tab => shouldDisplayTab(tab, newKind))
        setKind(newKind)
        setTabs(newTabs)
        setValue(newTabs[0].value)

        getNewInfo(newKind, item)
      }
    }
  }

  useEffect(() => {
    if (item) {
      getNewInfo(kind, item)
    }
    return () => {
      dispatch(resetOpenMenus())
    }
  }, [])

  useEffect(() => {
    if (item) {
      getNewInfo(kind, item)
    }
  }, [selectedSubnet])

  useEffect(() => {
    updateInfo()
  }, [kind, item, shouldRefresh])

  if (item === undefined) return null

  const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const onReturn = () => {
    dispatch(disableGeoEditor())
    // TODO: better navigation in panel
    if (isTrackSection(item) && item.track) { // Return on mother track for track section for the moment
      dispatch(updateItem(item.track))
    } else {
      dispatch(updateItem(undefined))
      PanelNavigator.goBack()
    }
  }

  const renderContent = () => (feedback && CRITICAL_ERROR_CODES.includes(feedback.code)
    ? <ErrorOverlay code={feedback.code} />
    : (
      <DetailsPanelContent
        kind={kind}
        value={value}
        tabs={tabs}
        handleTabChange={handleTabChange}
      />
    ))

  return (
    <PanelTemplate
      id="details-panel"
      onClickReturn={onReturn}
      headerContent={<DetailsPanelHeader kind={kind} hidePanel={onReturn} />}
      footerButton={isLoading
        ? undefined
        : <DetailsPanelButtons kind={kind} />}
    >
      {renderContent()}
    </PanelTemplate>
  )
}
