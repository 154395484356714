/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { Isolator } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { store } from 'Store'

const getDetails = createAsyncThunk<Isolator, string, ThunkApiConfig>(
  'isolator/getDetails',
  async (id: string, thunkApi) => {
    try {
      const response: Isolator = await get(`/midi-next/v2/${ObjectURI.Isolators}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Isolator>, string, ThunkApiConfig>(
  'isolator/getGeometry',
  async (id: string, thunkApi) => {
    try {
      const subnetId = store.getState().map.selectedSubnet.id
      const response: Feature<Geometry, Isolator> = await get(
        `/chartis/v2/layer/${ObjectLayer.Isolator}/geojson_feature/sch/`,
        { id, subnetId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Isolator, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'isolator/create',
  async (newIsolator: ObjOfStrOrNum | nestedObject, thunkApi) => {
    try {
      const response: Isolator = await post(`/midi-next/v2/${ObjectURI.Isolators}/`, newIsolator)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<Isolator, Isolator, ThunkApiConfig>(
  'isolator/updateById',
  async (updatedIsolator: Isolator, thunkApi) => {
    try {
      const response: Isolator = await patch(
        `/midi-next/v2/${ObjectURI.Isolators}/${updatedIsolator.id}`,
        updatedIsolator,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'isolator/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.Isolators}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Isolator, ValidationParams, ThunkApiConfig>(
  'isolator/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Isolator = await post(`/midi-next/v2/${ObjectURI.Isolators}/${id}/${operation}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const IsolatorServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
}

export default IsolatorServices
