import { SentryConfigured } from '@osrdata/app_core'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorker from './serviceWorker'
import { persistor, store } from './Store'

import 'variables.scss'
import App from './App'

SentryConfigured()
initTranslation()
const container = document.getElementById('root') as HTMLElement
const root = ReactDOMClient.createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
