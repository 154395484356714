import { MapLayerObject } from 'components/Map/utils'
import { InstructionType, LayersByInstructionType } from 'objects/types/instructions'
import { PanelName, PanelState } from 'reducers/panels/panel'
import { store } from 'Store'
import { MidiObject } from 'objects/types'

const getCSSValue = (name: string): string => getComputedStyle(document.documentElement).getPropertyValue(name)

export const getActivePanel = (): PanelName => {
  const { panelHistory } = store.getState().panel as PanelState
  return panelHistory[panelHistory.length - 1]
}

export const isStringEmpty = (s: string | undefined): boolean => (
  s === undefined || s.trim().length === 0
)

export const filterDuplicateFeatures = (key: keyof MidiObject, type: InstructionType,
  features: MapLayerObject[]): MapLayerObject[] => {
  const filteredFeatures = features.filter(
    (f: MapLayerObject) => LayersByInstructionType[type].includes(f.sourceLayer),
  )
  const uniqueFeatures = [...new Map(filteredFeatures.map(item => [item.properties[key], item])).values()]
  return uniqueFeatures
}

export default getCSSValue
