import { Feature, Polygon } from '@nebula.gl/edit-modes'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import MapGL, { MapRef } from 'react-map-gl'
import { DrawPolygonMode, Editor } from 'react-map-gl-draw'
import { useDispatch, useSelector } from 'react-redux'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { EditorMode, EditorModeName, EDITOR_MODES } from 'components/GeoEditor/types'
import ObjectLayers from 'components/Map/ObjectLayers/ObjectLayers'
import mapStyle from 'components/Map/style_empty.json'
import Toolbar from 'components/Map/Toolbar/Toolbar'
import {
  addImagesToInstance,
  DEFAULT_VIEWPORT, MAX_ZOOM, refreshTiles, transformRequest,
} from 'components/Map/utils'
import { ObjectLayer } from 'objects/types/const'
import { InstructionType } from 'objects/types/instructions'
import {
  InstructionState, setActiveSubStep, setInstructionGeom, setPerimeterModification,
} from 'reducers/instruction'
import { MapState, setLayersToUpdate } from 'reducers/map'
import InstructionServices from 'services/InstructionServices'
import { RootState } from 'Store'
import './ItemsSelection.scss'

type UpdateEvent = {
  data: Feature[];
  editType: string;
}

type Props = {
  title: string;
  subtitle?: string;
}

const defaultProps = {
  subtitle: undefined,
}

const buttonStyle = {
  position: 'absolute',
  bottom: '15px',
  left: 0,
  right: 0,
  margin: 'auto',
  width: '30%',
  borderRadius: '23px',
}

export default function SelectMap({ title, subtitle }: Props): ReactElement {
  const mapRef = useRef<MapRef>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const [bbox, setBbox] = useState<Feature | undefined>()
  const [mode, setMode] = useState<EditorMode>(EDITOR_MODES[EditorModeName.DrawPolygon])
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { selectedSubnet } = useSelector((state: RootState) => state.map) as MapState
  const [disableScroll, setDisableScroll] = useState(false)
  const { layers } = useSelector((state: RootState): MapState => state.map)

  useEffect(() => {
    dispatch(setLayersToUpdate([ObjectLayer.Track, ObjectLayer.TrackSection]))
    dispatch(setPerimeterModification(true))

    return () => { dispatch(setPerimeterModification(false)) }
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      addImagesToInstance(mapRef.current)
    }
  }, [layers])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [selectedSubnet])

  const onUpdate = (params: UpdateEvent) => {
    if (params.editType !== 'addTentativePosition') {
      if (mode instanceof DrawPolygonMode) setMode(EDITOR_MODES[EditorModeName.Edit])
      setBbox(params.data[0])
    }
  }

  const isButtonDisabled = bbox === undefined

  return (
    <>
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper d-flex justify-content-center text-center flex-column">
            <h1>{t(title)}</h1>
            {subtitle && <h3>{t(subtitle)}</h3>}
          </div>
        </div>
      </div>
      <div className="w-100" style={{ flex: 1 }}>
        <MapGL
          {...viewport}
          ref={mapRef}
          transformRequest={transformRequest}
          maxZoom={MAX_ZOOM}
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => { setViewport(newViewport) }}
          clickRadius={10}
          preventStyleDiffing
          scrollZoom={!disableScroll}
        >
          <ObjectLayers hoveredEvent={undefined} />
          <Editor
            style={{ width: '100%', height: '100%' }}
            clickRadius={12}
            mode={mode}
            features={bbox ? [bbox] : undefined}
            onUpdate={onUpdate}
            editHandleShape="circle"
            selectedFeatureIndex={0}
            selectable
          />
          <Toolbar disableScroll={setDisableScroll} />
        </MapGL>
      </div>
      <SimpleButton
        disabled={isButtonDisabled}
        customStyle={buttonStyle}
        style={ButtonStyle.primary}
        title={t('Instruction.button.continue')}
        onClick={() => {
          dispatch(setInstructionGeom(bbox?.geometry as unknown as Polygon))
          dispatch(InstructionServices.getItemsInBbox({
            bpolygon: bbox?.geometry as Polygon,
            type: instruction.type as InstructionType,
            date: instruction.applicationDate as string,
            subnet_id: selectedSubnet?.id || '',
          }))
          dispatch(setActiveSubStep(1))
        }}
      />
    </>
  )
}

SelectMap.defaultProps = defaultProps
