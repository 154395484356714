import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useState } from 'react'
import { Popup } from 'react-map-gl'
import { useSelector } from 'react-redux'

import Paper from '@mui/material/Paper'

import { GeoEditorState } from 'reducers/geoEditor'
import { RootState } from 'Store'

import { filterDuplicateFeatures } from 'helpers'
import { InstructionState } from 'reducers/instruction'
import { InstructionType } from 'objects/types/instructions'
import onFeatureClick from '../onFeatureClick'
import {
  getObjectPopupIcon, getStatusIcon, getTitle, LAYERS_LABEL, STATUS_LABEL,
} from '../popupUtils'
import { FeatureClickEvent, MapLayerObject } from '../utils'
import './FeatureClickPopup.scss'

type Props = {
  event: FeatureClickEvent | undefined;
  setEvent: (e: FeatureClickEvent | undefined) => void;
}

const FILTERED_KEY = 'id'

export default function FeatureClickPopup({ event, setEvent }: Props): ReactElement | null {
  const { t } = useTranslation()
  const { active: isGeoEditorActive } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)
  const [display, setDisplay] = useState(true)

  if (!event || event?.features === undefined || event.features.length === 0 || isGeoEditorActive) return null

  const coords = event?.lngLat

  const onObjectClick = (e: MapLayerObject) => {
    onFeatureClick(e)
    setDisplay(false)
    setTimeout(() => setEvent(undefined), 300)
  }

  const uniqueFeatures = filterDuplicateFeatures(FILTERED_KEY, instruction.type as InstructionType, event.features)

  return display ? (
    <div id="feature-click-popup">
      <Popup
        longitude={coords[0]}
        latitude={coords[1]}
        closeButton={false}
        className="popup-wrapper"
        tipSize={0}
        anchor="bottom"
        captureClick
        captureScroll
        capturePointerMove
      >
        <Paper className="popup">
          <div className="title">
            {`${uniqueFeatures.length} objet(s) trouvé(s)`}
          </div>
          <div className="scroll-list">
            {uniqueFeatures.map((e: MapLayerObject) => (
              <div
                key={e.properties.id}
                onClick={() => onObjectClick(e)}
                tabIndex={-1}
                role="button"
                className="object-wrapper d-flex my-2 px-2"
              >
                <div className="pr-1">
                  <img src={getObjectPopupIcon(e)} alt="object icon" className="icon" />
                </div>
                <div className="d-flex justify-content-between content-wrapper">
                  <div className="title-wrapper d-flex flex-column">
                    <div className="title">{t(getTitle(e, t))}</div>
                    <div className="sub-title">{t(LAYERS_LABEL[e.sourceLayer])}</div>
                  </div>
                  <div className="d-flex align-items-center status-wrapper">
                    <div
                      className={`status-title color-${e.properties.status}`}
                    >
                      {t(STATUS_LABEL[e.properties.status])}
                    </div>
                    {getStatusIcon(e.properties.status) && (
                    <div>
                      <img
                        src={getStatusIcon(e.properties.status)}
                        alt="status icon"
                        className="status-icon"
                      />
                    </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Paper>
      </Popup>
    </div>
  ) : null
}
