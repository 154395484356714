import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { Instruction, User } from 'objects/types/instructions'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminState } from 'reducers/admin'
import { InstructionState, resetInstruction, setInstruction } from 'reducers/instruction'
import { RootState } from 'Store'
import InstructionServices from 'services/InstructionServices'
import Loader from 'components/Common/Loader'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import UserBadges from '../UserBadges'
import Parameters from './Parameters/Parameters'
import './InstructionDashboard.scss'

type Props = {
  instruction: Instruction;
}

const DEFAULT_FILE_NAME = 'Export'

export default function InstructionDashboard({ instruction }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { usersInfo } = useSelector((state: RootState) => state.admin) as AdminState
  const { exportedData, loading } = useSelector((state: RootState) => state.instruction) as InstructionState
  const [displayParamsModal, setDisplayParamsModal] = useState(false)

  useEffect(() => {
    if (exportedData !== undefined) {
      const url = window.URL.createObjectURL(
        new Blob([exportedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${DEFAULT_FILE_NAME}.xlsx`)
      document.body.appendChild(link)
      link.click()
    }
  }, [exportedData])

  const downloadDispatchedData = () => {
    dispatch(InstructionServices.exportDispatchedData(instruction.id))
  }

  return (
    <div className="instruction-dashboard d-flex h-100 w-100">
      {loading && <Loader overlay />}
      <div className="parameters">
        <div>
          <h2>{`Utilisateurs (${instruction.users.length})`}</h2>
          <div className="my-3">
            <UserBadges userIds={instruction.users as User[]} usersInfo={usersInfo} />
          </div>
        </div>
        <div>
          <SimpleButton
            title={t('Instruction.dashboard.buttons.openParameters')}
            style={ButtonStyle.light}
            onClick={() => {
              dispatch(setInstruction(instruction))
              setDisplayParamsModal(true)
            }}
          />
        </div>
      </div>
      <div className="objects d-flex justify-content-center align-items-center">
        <div>
          {t('Instruction.dashboard.titles.itemsProgression')}
        </div>
      </div>
      <div className="overview d-flex flex-column">
        <div className="title">
          {t('Instruction.dashboard.titles.overview')}
        </div>
        <div>
          <SimpleButton
            title={t('Instruction.dashboard.buttons.exportToGaia')}
            style={ButtonStyle.rainbow}
            onClick={downloadDispatchedData}
          />
        </div>
      </div>
      {displayParamsModal && (
        <Parameters
          onClose={() => {
            setDisplayParamsModal(false)
            dispatch(resetInstruction())
          }}
        />
      )}
    </div>
  )
}
