/* eslint-disable @typescript-eslint/no-explicit-any */
import { Geometry } from '@nebula.gl/edit-modes'
import { get, patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { DEFAULT_SUBNET_SLUG } from 'config/config'
import {
  Feature, FeatureCollection, LineString, Polygon,
} from 'geojson'
import { fetchTSGeometryHelper } from 'objects/TrackSections/TrackSectionServices'
import { TrackSection } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { Subnet } from 'objects/types/subnets'
import ObjectURI from 'objects/uri'
import { AdminState } from 'reducers/admin'
import { MapState } from 'reducers/map'
import { store } from 'Store'
import { ThunkApiConfig } from 'types'
import { LineStringFeature } from './types'

const getDefaultSubnetId = (subnetsList: Subnet[]): string => (
  subnetsList.find(s => s.slug === DEFAULT_SUBNET_SLUG)?.id || ''
)

const getTrackSectionGeometry = createAsyncThunk<Feature<LineString, TrackSection>, string, ThunkApiConfig>(
  'geoEditor/getTrackSectionGeometry',
  async (id, thunkApi) => {
    const { selectedSubnet } = store.getState().map as MapState
    const { subnetsList } = store.getState().admin as AdminState
    try {
      return await fetchTSGeometryHelper(id, selectedSubnet?.id || getDefaultSubnetId(subnetsList))
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const updateTrackSectionGeometry = createAsyncThunk<TrackSection, Geometry, ThunkApiConfig>(
  'geoEditor/updateTrackSectionGeometry',
  async (geometry, thunkApi) => {
    try {
      const { item } = store.getState().detailsPanel
      const { selectedSubnet } = store.getState().map as MapState
      const response: TrackSection = await patch(
        `/midi-next/v2/${ObjectURI.TrackSections}/${item.id}/`,
        {
          geom: geometry,
          checksum: item.checksum,
          id: item.id,
        },
        {
          params: {
            subnetSlug: selectedSubnet?.slug || DEFAULT_SUBNET_SLUG,
          },
        },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTrackSectionsByBbox = createAsyncThunk(
  'geoEditor/getTrackSectionsByBbox',
  async (bbox?: Polygon) => {
    const { subnetsList } = store.getState().admin as AdminState
    const { selectedSubnet } = store.getState().map as MapState
    const response: FeatureCollection = await get(
      `/chartis/v2/layer/${ObjectLayer.TrackSection}/geojson/sch/`,
      { bbox, page_size: 8000, subnetId: selectedSubnet?.id || getDefaultSubnetId(subnetsList) },
    )
    return response.features.filter(f => f.geometry.type !== null) as LineStringFeature[]
  },
)

const GeoEditorService = {
  getTrackSectionGeometry,
  updateTrackSectionGeometry,
  getTrackSectionsByBbox,
}

export default GeoEditorService
