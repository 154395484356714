import {
  createSlice, isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import { Instruction, InstructionItem, InstructionType } from 'objects/types/instructions'
import InstructionServices from 'services/InstructionServices'
import { Role, User } from 'services/cerbereTypes'
import UserServices from 'services/UserServices'
import { Polygon } from 'geojson'

export interface InstructionState {
  displayCreation: boolean;
  displayNewVersion: boolean;
  activeStep: number;
  activeSubStep: number; // Used when there are several screens for 1 step
  instruction: Partial<Instruction>;
  items: InstructionItem[]; // detailed list of the instruction's items
  totalItems: number; // detailed list of the instruction's items
  loading: boolean;
  supervisors: User[];
  operators: User[];
  administrators: User[];
  hasError: boolean;
  exportedData?: ArrayBuffer;
  perimeterModification: boolean;
}

const initialState: InstructionState = {
  displayCreation: false,
  displayNewVersion: false,
  activeStep: 0,
  activeSubStep: 0,
  instruction: {},
  loading: false,
  administrators: [],
  supervisors: [],
  operators: [],
  items: [],
  totalItems: 0,
  hasError: false,
  perimeterModification: false,
}

export const instruction = createSlice({
  name: 'instruction',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleDisplayCreation: state => {
      state.displayCreation = !state.displayCreation
    },
    toggleDisplayNewVersion: state => {
      state.displayNewVersion = !state.displayNewVersion
    },
    setInstruction: (state, action: PayloadAction<Partial<Instruction>>) => {
      state.instruction = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setActiveSubStep: (state, action: PayloadAction<number>) => {
      state.activeSubStep = action.payload
    },
    setInstructionType: (state, action: PayloadAction<InstructionType>) => {
      state.instruction.type = action.payload
    },
    setInstructionName: (state, action: PayloadAction<string>) => {
      state.instruction.name = action.payload
    },
    setInstructionRef: (state, action: PayloadAction<string>) => {
      state.instruction.reference = action.payload
    },
    setInstructionVersion: (state, action: PayloadAction<string>) => {
      state.instruction.version = action.payload
    },
    setInstructionVersionDate: (state, action: PayloadAction<string>) => {
      state.instruction.versionDate = action.payload
    },
    setInstructionDate: (state, action: PayloadAction<string>) => {
      state.instruction.applicationDate = action.payload
    },
    setInstructionGeom: (state, action: PayloadAction<Polygon>) => {
      state.instruction.geom = action.payload
    },
    setItems: (state, action: PayloadAction<InstructionItem[]>) => {
      state.items = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPerimeterModification: (state, action: PayloadAction<boolean>) => {
      state.perimeterModification = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.create.fulfilled, (state, action) => {
      state.instruction = action.payload
      state.activeStep += 1
    })
    builder.addCase(InstructionServices.getItemsInBbox.fulfilled, (state, action) => {
      state.items = action.payload
      state.activeSubStep += 1
    })

    // Add items
    builder.addCase(InstructionServices.addItems.pending, state => {
      state.activeSubStep = 3
    })
    builder.addCase(InstructionServices.addItems.fulfilled, state => {
      state.activeStep += 1
      state.activeSubStep = 0
    })

    // Add users
    builder.addCase(InstructionServices.addUsers.pending, state => {
      state.activeSubStep = 1
    })
    builder.addCase(InstructionServices.addUsers.fulfilled, (state, action) => {
      state.instruction = action.payload
      state.activeSubStep = 2
    })

    builder.addCase(UserServices.getGroupMembers.fulfilled, (state, action) => {
      switch (action.payload.role) {
        case Role.operator:
          state.operators = action.payload.results
          break
        case Role.supervisor:
          state.supervisors = action.payload.results
          break
        case Role.admin:
          state.administrators = action.payload.results
          break
        default:
          break
      }
    })
    builder.addCase(
      InstructionServices.getItemsDetailsList.fulfilled, (state, action) => {
        state.totalItems = action.payload.totalItems
        state.items = action.payload.items
        state.loading = false
      },
    )

    builder.addCase(InstructionServices.getItemsDetailsList.rejected, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.getDetails.fulfilled, (state, action: PayloadAction<Partial<Instruction>>) => {
      state.instruction = action.payload
      state.hasError = false
      state.loading = false
    })

    builder.addCase(InstructionServices.getDetails.pending, state => {
      state.loading = true
    })

    builder.addCase(InstructionServices.exportDispatchedData.fulfilled, (
      state, action: PayloadAction<ArrayBuffer>,
    ) => {
      state.loading = false
      state.exportedData = action.payload
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.getDetails.rejected,
      InstructionServices.updateItems.rejected,
      InstructionServices.exportDispatchedData.fulfilled,
    ), state => {
      state.loading = false
      state.hasError = true
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.addItems.pending,
      InstructionServices.updateItems.pending,
      InstructionServices.getItemsDetailsList.pending,
      InstructionServices.deleteItem.pending,
      InstructionServices.exportDispatchedData.pending,
    ), state => {
      state.loading = true
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.update.fulfilled,
      InstructionServices.addUsers.fulfilled,
      InstructionServices.updateItems.fulfilled,
    ), (state, action) => {
      state.instruction = action.payload
      state.loading = false
      state.hasError = false
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.getItemsInBbox.rejected,
      InstructionServices.addItems.rejected,
      InstructionServices.addUsers.rejected,
      InstructionServices.deleteItem.rejected,
    ), state => {
      state.activeSubStep -= 1
      state.loading = false
      state.hasError = true
    })
  },
})

export const {
  toggleDisplayCreation,
  toggleDisplayNewVersion,
  setInstruction,
  setActiveStep,
  setActiveSubStep,
  setInstructionType,
  setInstructionName,
  setInstructionDate,
  setInstructionGeom,
  setInstructionRef,
  setInstructionVersion,
  setInstructionVersionDate,
  setItems,
  setLoading,
  reset: resetInstruction,
  setPerimeterModification,
} = instruction.actions

export default instruction.reducer
