/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { TrackIdentificationSystem } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { store } from 'Store'
import TRACK_IDENTIFICATION_SYSTEM_ATTRIBUTES from './TISAttributes'

export const getTISDetailsRaw = async (id: string): Promise<TrackIdentificationSystem> => (
  get(`/midi-next/v2/${ObjectURI.TIS}/${id}`)
)

const getDetails = createAsyncThunk<TrackIdentificationSystem, string, ThunkApiConfig>(
  'tis/getDetails',
  async (id: string, thunkApi) => {
    try {
      return await getTISDetailsRaw(id)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, TrackIdentificationSystem>, string, ThunkApiConfig>(
  'tis/getGeometry',
  async (id: string, thunkApi) => {
    try {
      const subnetId = store.getState().map.selectedSubnet.id
      const response: Feature<Geometry, TrackIdentificationSystem> = await get(
        `/chartis/v2/layer/${ObjectLayer.TrackIdentificationSystem}/geojson_feature/sch/`,
        { id, subnetId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<TrackIdentificationSystem, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'tis/create',
  async (newTIS: ObjOfStrOrNum | nestedObject, thunkApi) => {
    try {
      const response: TrackIdentificationSystem = await post(`/midi-next/v2/${ObjectURI.TIS}/`, newTIS)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<TrackIdentificationSystem, TrackIdentificationSystem, ThunkApiConfig>(
  'tis/updateById',
  async (updatedTIS, thunkApi) => {
    try {
      const trackId = updatedTIS.track ? updatedTIS.track.id : undefined
      const response: TrackIdentificationSystem = await patch(
        `/midi-next/v2/${ObjectURI.TIS}/${updatedTIS.id}`,
        {
          ...filterFields(updatedTIS, getUpdatedFields(TRACK_IDENTIFICATION_SYSTEM_ATTRIBUTES())),
          track: { id: trackId },
        },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'tis/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/track-identification-systems/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<TrackIdentificationSystem, ValidationParams, ThunkApiConfig>(
  'tis/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: TrackIdentificationSystem = await post(`/midi-next/v2/${ObjectURI.TIS}/${id}/${operation}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TrackIdentificationSystemServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
}

export default TrackIdentificationSystemServices
