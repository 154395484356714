/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { ElectricalElement } from 'objects/types/protections'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { store } from 'Store'
import { addExtremity, deleteExtremity, switchExtremityDirection } from './extremities'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import ELECTRICAL_ELEMENT_ATTRIBUTES from '../ElectricalElementAttributes'

const getDetails = createAsyncThunk<ElectricalElement, string, ThunkApiConfig>(
  'electricalElement/getDetails',
  async (id, thunkApi) => {
    try {
      const response: ElectricalElement = await get(`/midi-next/v2/${ObjectURI.ElectricalElements}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, ElectricalElement>, string, ThunkApiConfig>(
  'electricalElement/getGeometry',
  async (id, thunkApi) => {
    try {
      const subnetId = store.getState().map.selectedSubnet.id
      const response: Feature<Geometry, ElectricalElement> = await get(
        `/chartis/v2/layer/${ObjectLayer.ElectricalElement}/geojson_feature/sch/`,
        { id, subnetId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<ElectricalElement, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'electricalElement/create',
  async (newElectricalElement, thunkApi) => {
    try {
      const response: ElectricalElement = await post(
        `/midi-next/v2/${ObjectURI.ElectricalElements}/`,
        newElectricalElement,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<ElectricalElement, Partial<ElectricalElement>, ThunkApiConfig>(
  'electricalElement/updateById',
  async (updatedElectricalElement, thunkApi) => {
    try {
      const response: ElectricalElement = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        filterFields(updatedElectricalElement, getUpdatedFields(ELECTRICAL_ELEMENT_ATTRIBUTES())),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'electricalElement/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.ElectricalElements}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<ElectricalElement, ValidationParams, ThunkApiConfig>(
  'electricalElement/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: ElectricalElement = await post(
        `/midi-next/v2/${ObjectURI.ElectricalElements}/${id}/${operation}/`,
        {},
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ElectricalElementServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addExtremity,
  switchExtremityDirection,
  deleteExtremity,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
}

export default ElectricalElementServices
