/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { SubSector } from 'objects/types/protections'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { store } from 'Store'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'
import { addChild, deleteChild } from './children'
import SUB_SECTOR_ATTRIBUTES from '../SubSectorAttributes'

const getDetails = createAsyncThunk<SubSector, string, ThunkApiConfig>(
  'subSector/getDetails',
  async (id, thunkApi) => {
    try {
      const response: SubSector = await get(`/midi-next/v2/${ObjectURI.SubSectors}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, SubSector>, string, ThunkApiConfig>(
  'subSector/getGeometry',
  async (id, thunkApi) => {
    try {
      const subnetId = store.getState().map.selectedSubnet.id
      const response: Feature<Geometry, SubSector> = await get(
        `/chartis/v2/layer/${ObjectLayer.SubSector}/geojson_feature/sch/`,
        { id, subnetId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<SubSector, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'subSector/create',
  async (newSector, thunkApi) => {
    try {
      const response: SubSector = await post(
        `/midi-next/v2/${ObjectURI.SubSectors}/`,
        newSector,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<SubSector, Partial<SubSector>, ThunkApiConfig>(
  'subSector/updateById',
  async (updatedSector, thunkApi) => {
    try {
      const response: SubSector = await patch(
        `/midi-next/v2/${ObjectURI.SubSectors}/${updatedSector.id}`,
        filterFields(updatedSector, getUpdatedFields(SUB_SECTOR_ATTRIBUTES())),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'subSector/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.SubSectors}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<SubSector, ValidationParams, ThunkApiConfig>(
  'subSector/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: SubSector = await post(
        `/midi-next/v2/${ObjectURI.SubSectors}/${id}/${operation}/`,
        {},
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const SubSectorServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addIncompatibleObject,
  deleteIncompatibleObject,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addChild,
  deleteChild,
}

export default SubSectorServices
