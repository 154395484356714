import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ObjectLayer } from 'objects/types/const'
import { InstructionType, LayersByInstructionType, S6ObjectLayers } from 'objects/types/instructions'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InstructionState } from 'reducers/instruction'
import { addLayer, MapState, removeLayer } from 'reducers/map'
import { RootState } from 'Store'

type Props = {
  display: boolean;
  captureClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const LAYERS = [
  {
    key: ObjectLayer.Track,
    title: 'Layers.tracks',
  },
  {
    key: ObjectLayer.TrackSection,
    title: 'Layers.trackSections',
  },
  {
    key: ObjectLayer.Isolator,
    title: 'Layers.isolators',
  },
  {
    key: ObjectLayer.Signal,
    title: 'Layers.signals',
  },
  {
    key: ObjectLayer.Feeder,
    title: 'Layers.feeders',
  },
  {
    key: ObjectLayer.TrackProtection,
    title: 'Layers.trackProtections',
  },
  {
    key: ObjectLayer.TrackProtectionGroup,
    title: 'Layers.trackProtectionGroups',
  },
  {
    key: ObjectLayer.ElectricalElement,
    title: 'Layers.electricalElements',
  },
  {
    key: ObjectLayer.ElectricalProtectionGroup,
    title: 'Layers.electricalProtectionGroups',
  },
  {
    key: ObjectLayer.Sector,
    title: 'Layers.sectors',
  },
  {
    key: ObjectLayer.SubSector,
    title: 'Layers.subSectors',
  },
]

export default function LayersMenu({ display, captureClick }: Props): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { layers } = useSelector((state: RootState): MapState => state.map)
  const { instruction, perimeterModification } = useSelector((state: RootState): InstructionState => state.instruction)
  const objectsToShow = perimeterModification
    ? S6ObjectLayers : LayersByInstructionType[instruction.type as InstructionType]
  const layersToShow = LAYERS.filter(l => objectsToShow.includes(l.key))

  return (
    <div
      className={`toolbar-menu ${display ? 'display' : ''}`}
      onClickCapture={e => captureClick(e)}
    >
      {layersToShow.map(layer => (
        <div key={layer.key} className="d-flex align-items-center">
          <Checkbox
            checked={layers.includes(layer.key)}
            onChange={e => {
              if (e.target.checked) dispatch(addLayer(layer.key))
              else dispatch(removeLayer(layer.key))
            }}
            color="primary"
          />
          <div className="title">{t(layer.title)}</div>
        </div>
      ))}
    </div>

  )
}
