import getExtremityAttributes, { ExtremityType } from 'objects/common/Extremities/ExtremityAttributes'
import ELECTRICAL_ELEMENT_ATTRIBUTES from 'objects/ElectricalElements/ElectricalElementAttributes'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES from
  'objects/ElectricalProtectionGroups/ElectricalProtectionGroupAttributes'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FEEDER_ATTRIBUTES from 'objects/Feeders/FeederAttributes'
import FeederServices from 'objects/Feeders/FeederServices'
import ISOLATOR_ATTRIBUTES from 'objects/Isolators/IsolatorAttributes'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SECTOR_ATTRIBUTES from 'objects/Sectors/SectorAttributes'
import SectorServices from 'objects/Sectors/SectorServices'
import SIGNAL_ATTRIBUTES from 'objects/Signals/SignalAttributes'
import SignalServices from 'objects/Signals/SignalServices'
import SUB_SECTOR_ATTRIBUTES from 'objects/SubSectors/SubSectorAttributes'
import TRACK_IDENTIFICATION_SYSTEM_ATTRIBUTES from 'objects/TrackIdentificationSystems/TISAttributes'
import TISServices from 'objects/TrackIdentificationSystems/TISServices'
import TRACK_PROTECTION_GROUP_ATTRIBUTES from 'objects/TrackProtectionGroups/TrackProtectionGroupAttributes'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TRACK_PROTECTION_ATTRIBUTES from 'objects/TrackProtections/TrackProtectionAttributes'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TRACK_ATTRIBUTES from 'objects/Tracks/TrackAttributes'
import TrackServices from 'objects/Tracks/TrackServices'
import TRACK_SECTION_ATTRIBUTES from 'objects/TrackSections/TrackSectionAttributes'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import { ExtraKind, ObjectKind } from 'objects/types/const'
import { InstructionState } from 'reducers/instruction'
import { store as reduxStore } from 'Store'
import { Attribute } from './attributes'
import SubSectorServices from './SubSectors/SubSectorServices'

const ELECTRICAL_ELEMENT_EXTREMITIES = [ExtremityType.orphan]
const ELECTRICAL_PROTECTION_GROUP_EXTREMITIES = [ExtremityType.orphan]
const FEEDER_EXTREMITIES = [ExtremityType.orphan]
const TRACK_PROTECTIONS_EXTREMITIES = [ExtremityType.orphan, ExtremityType.isolator, ExtremityType.signal]
const TRACK_PROTECTION_GROUP_EXTREMITIES = [ExtremityType.orphan]

export const AttributesOfKind = (store: typeof reduxStore): {[key: string]: Attribute[]} => ({
  [ObjectKind.ElectricalElement]: ELECTRICAL_ELEMENT_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.ElectricalProtectionGroup]: ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.Feeder]: FEEDER_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.Isolator]: ISOLATOR_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.Sector]: SECTOR_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.Signal]: SIGNAL_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.SubSector]: SUB_SECTOR_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.Track]: TRACK_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.TrackIdentificationSystem]: TRACK_IDENTIFICATION_SYSTEM_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.TrackProtection]: TRACK_PROTECTION_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.TrackProtectionGroup]: TRACK_PROTECTION_GROUP_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ObjectKind.TrackSection]: TRACK_SECTION_ATTRIBUTES(
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate },
  ),
  [ExtraKind.EEExtremity]: getExtremityAttributes(ELECTRICAL_ELEMENT_EXTREMITIES),
  [ExtraKind.EPGExtremity]: getExtremityAttributes(ELECTRICAL_PROTECTION_GROUP_EXTREMITIES),
  [ExtraKind.FeederExtremity]: getExtremityAttributes(FEEDER_EXTREMITIES, false),
  [ExtraKind.TPExtremity]: getExtremityAttributes(TRACK_PROTECTIONS_EXTREMITIES, true, true),
  [ExtraKind.TPGExtremity]: getExtremityAttributes(TRACK_PROTECTION_GROUP_EXTREMITIES),
})

export const GetGeometryServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.getGeometry,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.getGeometry,
  [ObjectKind.Feeder]: FeederServices.getGeometry,
  [ObjectKind.Isolator]: IsolatorServices.getGeometry,
  [ObjectKind.Sector]: SectorServices.getGeometry,
  [ObjectKind.Signal]: SignalServices.getGeometry,
  [ObjectKind.SubSector]: SubSectorServices.getGeometry,
  [ObjectKind.Track]: TrackServices.getGeometry,
  [ObjectKind.TrackIdentificationSystem]: TISServices.getGeometry,
  [ObjectKind.TrackProtection]: TrackProtectionServices.getGeometry,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.getGeometry,
  [ObjectKind.TrackSection]: TrackSectionServices.getGeometry,
}

export const GetDetailsServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.getDetails,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.getDetails,
  [ObjectKind.Feeder]: FeederServices.getDetails,
  [ObjectKind.Isolator]: IsolatorServices.getDetails,
  [ObjectKind.Sector]: SectorServices.getDetails,
  [ObjectKind.Signal]: SignalServices.getDetails,
  [ObjectKind.SubSector]: SubSectorServices.getDetails,
  [ObjectKind.Track]: TrackServices.getDetails,
  [ObjectKind.TrackIdentificationSystem]: TISServices.getDetails,
  [ObjectKind.TrackProtection]: TrackProtectionServices.getDetails,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.getDetails,
  [ObjectKind.TrackSection]: TrackSectionServices.getDetails,
}

export const CreateServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.create,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.create,
  [ObjectKind.Feeder]: FeederServices.create,
  [ObjectKind.Isolator]: IsolatorServices.create,
  [ObjectKind.Sector]: SectorServices.create,
  [ObjectKind.Signal]: SignalServices.create,
  [ObjectKind.SubSector]: SubSectorServices.create,
  [ObjectKind.Track]: TrackServices.create,
  [ObjectKind.TrackIdentificationSystem]: TISServices.create,
  [ObjectKind.TrackProtection]: TrackProtectionServices.create,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.create,
  [ObjectKind.TrackSection]: TrackSectionServices.create,
  [ExtraKind.EEExtremity]: ElectricalElementServices.addExtremity,
  [ExtraKind.EPGExtremity]: ElectricalProtectionGroupServices.addExtremity,
  [ExtraKind.FeederExtremity]: FeederServices.addExtremity,
  [ExtraKind.TPExtremity]: TrackProtectionServices.addExtremity,
  [ExtraKind.TPGExtremity]: TrackProtectionGroupServices.addExtremity,
}

export const UpdateServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.update,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.update,
  [ObjectKind.Feeder]: FeederServices.update,
  [ObjectKind.Isolator]: IsolatorServices.update,
  [ObjectKind.Sector]: SectorServices.update,
  [ObjectKind.Signal]: SignalServices.update,
  [ObjectKind.SubSector]: SubSectorServices.update,
  [ObjectKind.Track]: TrackServices.update,
  [ObjectKind.TrackIdentificationSystem]: TISServices.update,
  [ObjectKind.TrackProtection]: TrackProtectionServices.update,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.update,
  [ObjectKind.TrackSection]: TrackSectionServices.update,
}

export const DeleteServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.delete,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.delete,
  [ObjectKind.Feeder]: FeederServices.delete,
  [ObjectKind.Isolator]: IsolatorServices.delete,
  [ObjectKind.Sector]: SectorServices.delete,
  [ObjectKind.Signal]: SignalServices.delete,
  [ObjectKind.SubSector]: SubSectorServices.delete,
  [ObjectKind.Track]: TrackServices.delete,
  [ObjectKind.TrackIdentificationSystem]: TISServices.delete,
  [ObjectKind.TrackProtection]: TrackProtectionServices.delete,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.delete,
  [ObjectKind.TrackSection]: TrackSectionServices.delete,
}

export const ValidateServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.validate,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.validate,
  [ObjectKind.Feeder]: FeederServices.validate,
  [ObjectKind.Isolator]: IsolatorServices.validate,
  [ObjectKind.Sector]: SectorServices.validate,
  [ObjectKind.Signal]: SignalServices.validate,
  [ObjectKind.SubSector]: SubSectorServices.validate,
  [ObjectKind.Track]: TrackServices.validate,
  [ObjectKind.TrackIdentificationSystem]: TISServices.validate,
  [ObjectKind.TrackProtection]: TrackProtectionServices.validate,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.validate,
  [ObjectKind.TrackSection]: TrackSectionServices.validate,
}
